import { connect } from 'react-redux';

import AdventureText from './AdventureText';
import Stats from './Stats';
import UserInteraction from './UserInteraction';

const App = ({adventureState}) => {
  const className = 'App' +
    ( adventureState.isSmote ? ' smote': '' ) + 
    ( adventureState.damned ? ' damned': '' );

  return (
    <div className={className}>
      <h1 className="ui container">
        The <span className="avoidWrap">Old Testament</span> <span className="avoidWrap">Text Adventure</span>
      </h1>
      <AdventureText />
      <Stats />
      <UserInteraction />
    </div>
  );
}

const mapStateToProps = state => {
  return {
      adventureState: state.adventureState,
  }
}

export default connect(mapStateToProps)(App);
