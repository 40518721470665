import {
    ACTION_RESPONSE_TEXT,
} from '../data/text';

import {
    ACTION_HANDLERS,
    DEFAULT_STATE,
    POST_ACTION_HANDLERS,
} from '../data/adventureActions';

const adventureStateReducer = (currentAdventureState = DEFAULT_STATE, action) => {
    currentAdventureState = { 
        ...currentAdventureState,
        ...(action.type in ACTION_RESPONSE_TEXT
                ? { adventureText: ACTION_RESPONSE_TEXT[action.type] }
                : {}
        ),
        ...(action.type in ACTION_HANDLERS
                ? ACTION_HANDLERS[action.type](currentAdventureState, action)
                : {}
        ),
    };
    for ( var handler of POST_ACTION_HANDLERS ) {
        currentAdventureState = {
            ...currentAdventureState,
           ...(handler(currentAdventureState)),
        }
    }
    if (typeof(currentAdventureState.adventureText) === 'function') { // handle function adventureText templates
        currentAdventureState.adventureText = currentAdventureState.adventureText(currentAdventureState, action);
    }
    return currentAdventureState;
};

export default adventureStateReducer;
