import {
    TOGGLE_SHARE
} from '../actions/types';

export const DEFAULT_STATE = {
    showShare: false,
};

const uiStateReducer = (currentState = DEFAULT_STATE, action) => ({
    ...currentState,
    showShare: ( action.type === TOGGLE_SHARE ? action.showShare : currentState.showShare ),
});

export default uiStateReducer;
