import {
    ASK_FOR_SON,
    ASK_GOD_QUESTION,
    CONSULT_SAGE,
    EXIST,
    LOSE_HEADS,
    MAKE_CALF,
    REMIND_GOD,
    REPLACE_POTTAGE,
    STOP_BELIEVING,
    VISIT_PARENTS,
    VISIT_NEW_TESTAMENT,
    VISIT_SODOM,
    RISE,
} from '../actions/types';

export const __REPLACE_POTTAGE__CHECK = '__REPLACE_POTTAGE__CHECK';
export const __REPLACE_POTTAGE__CHECK_NONE = '__REPLACE_POTTAGE__CHECK_NONE';
export const __TOO_MANY_BOILS = '__TOO_MANY_BOILS';
export const __TOO_MANY_REMINDERS = '__TOO_MANY_REMINDERS';

export const ACTION_TEXTS = {
    [ASK_FOR_SON]: <span>Ask God <br />for a son</span>,
    [CONSULT_SAGE] : <span>Consult a sage <br />for advice</span>,
    [EXIST]: <span>Exist<br />unobtrusively</span>,
    [LOSE_HEADS] : <span>Lose some <br />Gentile heads</span>,
    [MAKE_CALF] : <span>Make a <br />Golden Calf</span>,
    [REMIND_GOD] : <span>Remind God<br />of the Covenant</span>,
    [REPLACE_POTTAGE]: <span>Replace<br />pottage</span>,
    [RISE]: <span>Rise from <br />the dead</span>,
    [STOP_BELIEVING] : <span>Stop believing <br />in God</span>,
    [VISIT_NEW_TESTAMENT] : <span>Visit the<br />New Testament</span>,
    [VISIT_PARENTS] : <span>Visit <br />your parents</span>,
    [VISIT_SODOM]: <span>Visit<br />Sodom and Gomorrah</span>,
};

export const START_TEXT = (
    <div>
        <p>
            Welcome to the world!
        </p>
        <p>
            You, sir, are in luck, for the God of this universe has deigned you one of his chosen people.
        </p>
        <p>
            With you and your descendants he has made a covenant, an eternal promise that you will benefit from his benevolence as
            long as you obey the law and follow the path of the righteous.
        </p>
    </div>
);

// Text responses to an action
// map of [action.type] to jsx, or
//   function(state,action) that will return a templated jsx
export const ACTION_RESPONSE_TEXT = {
    [ASK_FOR_SON]: (
        <div>
            <p>
                Your favorite wife is barren.
                You ask God that she might bear a son, for nothing would make you happier.
                God, the Almighty, the Glorious, in a beautiful show of his love for you, grants your wish.
                Your son is the light of your life.  With every passing day he becomes more precious to you.
            </p>
                <p>
                One day, however, God calls in his debts.
                Heretofore at least professing to care for humans, he has now developed a blood lust, and demands that you
                sacrifice your son to demonstrate that your slavishness to God exceeds all else,
                including your sense of human decency.
            </p>
            <p>
                Will you offer your son to the Lord as a sacrifice?
            </p>
        </div>
    ),

    [ASK_GOD_QUESTION]: (currentAdventureState, action) =>
        <div>
            <span className="god">
                "'{action.questionText}'
                ... Hmmmm.... let me think,
            </span>" God muses.
            <span className='god'>
                "Oh, yes, I forgot, I DON'T HAVE TO ANSWER THE QUESTIONS OF PUNY MORTALS LIKE YOU.
                MY JUDGEMENT IS BEYOND THE COMPREHENSION OF MAN.
            </span>
            <br />
            <br />
            <p>
                <span className='god'>
                    Where were you when I made the mountains?
                    Can you bind the chains of the Pleiades, or loose the cords of Orion?
                    Where is the way to the dwelling of light, and where is the place of darkness?" 
                </span>
            </p>
            <p>
                You wander away as God rants on, sporting a few extra boils for your curiosity.
            </p>
            <p>
                You resolve to spend the rest of eternity wallowing in ignorance.
            </p>
        </div>,

    [CONSULT_SAGE]: (
        <div>
            <p>
                You visit the village wise man to learn at his feet.<br />
                You ask the wise man why it is that the innocent suffer.
            </p>
            <p>
                <span className='man'>"God tells us that the righteous are rewarded with good fortune,
                so those that suffer must be guilty of some sin,</span>"
                the wise man tells you.
                You are impressed by the clarity of his logic.
            </p>
            <p>
                God, however, is displeased with the light in which the wise man has cast him.
                <span className='god'>"That makes no sense! What about babies that are born deformed! How do you explain that?
                How dare you pretend to understand my divine justice?" </span> he thunders down from the heavens at the wise man.
            </p>
            <p>
                The wise man is inflicted with a plague of horrible boils.
                You lend him your trusty boil-lancing potshard and look on sympathetically as he writhes.
            </p>
        </div>
    ),

    [LOSE_HEADS]: (
        <div>
            <p>
                In a fit of rage, you brutally massacre several thousand people.
                Luckily, these people are not God's chosen people, and they may be killed with impunity.
            </p>
            <p>
                God is so pleased with your demonstration that he and his people are stronger than the infidels and their gods that he 
                rewards you with several sons and the disappearance of any boils from which you may be suffering. 
            </p>
            <p>
                On a completely unrelated note, God, as part of a bet with Satan, has killed all of your children 
                to see how you will react. Remember, keep a stiff upper lip!  God is counting on you!
            </p>
        </div>
    ),

    [MAKE_CALF]: (
        <div>
            <p>
                You and your neighbors are sitting around one day with nothing to do when one of them suggests that you make a
                golden calf and then use it as the centerpiece of orgiastic rituals in worship of heathen gods.
                Game as always, you collect the jewelry and hard currency of the group, melt it down, and fashion quite
                an impressive little idol.
            </p>
            <p>
                You're in the middle of revelry the likes of which the Mediterranean basin has never seen when your brother,
                the neighborhood goody-two-shoes, returns from one of his long conversations with God.
                <span className='man'>"I can't believe you're worshiping other gods! </span>" he berates you.
                <span className='man'>"You know how testy our God gets about that! He wanted to unleash his white-hot wrath on you and find a 
                new group of chosen people for me to lead, but I talked him out of it.</span>"
            </p>
            <p>
                Thank goodness! You breath a sigh of relief and silently praise the Lord's mercy.
                <span className='man'>"God has decided, compassionate as he is,</span>" your brother continues,
                <span className='man'>"that your only punishment will be that each man will draw his sword and fall on his brothers, 
                sons, and neighbors in a blood-soaked frenzy.</span>"
            </p>
            <p>
                The next few hours are the most horrific of your life, and all your children die agonizingly painful deaths.
                You survive, however, and God, evidently impressed with your leadership abilities, chooses you to become the 
                beginning of a line of priests who will help his people along the path righteousness and be entitled to
                a large portion of their hard-earned wealth.
            </p>
            <p>
                Your flock of goats is soon ten times as large.
            </p>
        </div>
    ),
    
    [REMIND_GOD]: (
        <div>
            <p>
                Perplexed by the fact that your life does not seem to be going particularly well despite your blameless behavior, 
                you dig your copy of the covenant out of your files and spend some time poring over the fine print. 
                After a few hours of careful study, you address God from your usual place of abject worship.
            </p>
            <p>
                <span className='man'>
                    "Oh Benevolent One, I was just wondering about something.
                    The covenant reads,
                </span>
            </p>
            <p className="quoted">
                <span className='god'>
                    'I, the aforementioned Almighty Being,
                    In Perpetuity, blah blah blah, do hereby Promise My Chosen People, the aforementioned insignificant mortals,
                    that if you will Obey the Lord your God all these enumerated Blessings shall come upon you.
                    Blessed shall be the Fruit of your Womb, the Fruit of your Ground, and the Fruit of your Livestock,
                    both the increase of your Cattle and the issue of your Flock, etcetera, etcetera.'
                </span>
            </p>
            <p>
                <span className='man'>
                    Where would the suffering of my innocent loved ones fit into this?"
                </span>
            </p>
            <p>
                There is a pointed, ominous silence from above.
                <br />
                You slink away, your curiosity sated for now.
            </p>
        </div>
    ),

    [STOP_BELIEVING]: (
        <div>
            <p>
                Pushed to the breaking point by the seemingly arbitrary nature of the universe, you decide that there must not be a God.
            </p>
            <p>
                This is not a very clever decision on your part.
            </p>
            <p>
                One wonders how you have managed to forget the many, many times that God has spoken directly to you.
                In addition, isn't believing in God the safest choice to make?
                If there is no God, there's no harm in believing in him, right?
                And, as you are about to discover, if there is a God and you don't believe in him, you're sort of screwed.
                Why not just hedge your bets?  All your children and goats die.  A terrible plague of boils is visited upon you.
                God and Blaise Pascal laugh derisively at you from on high.
            </p>
            <p>
                You cease to doubt God's existence.
            </p>
        </div>
    ),

    [VISIT_PARENTS]: (
        <div>
            <p>
                Ever the dutiful child, you trek across the wilderness to visit your parents for the holidays.
            </p>
            <p>
                Your dad has recently invented alcohol. Unfortunately for you, he did not simultaneously discover self-control.
                You enter his tent looking forward to a joyful greeting from your father and instead find him naked and unconscious
                on the floor. You stumble out, horrified, and your brothers cover your father with a blanket.
            </p>
            <p>
                After he regains consciousness, your father curses you for eternity for gazing on that which you should not.
            </p>
            <p>
                He also takes your pottage and gives it to your brothers.
            </p>
        </div>
    ),

    [VISIT_NEW_TESTAMENT]: (
        <div>
            <p>
                One day while you and God are shootin' the breeze, he tells you that he is omnibenevolent.
                You ask why he decided to change.  He looks perplexed and asks <span className='god'>"Change?" </span>
                You decide not to push the issue.
            </p>
            <p>
                The next day your wife gives birth to a baby with no limbs.
                Perplexed, you ask God why your innocent child has been punished.
                <span className='god'>"Oh,</span>" says God, <span className='god'>"I forgot to tell you.
                There's this other divine being named Satan who is completely evil, and he's responsible for 
                undeserved suffering and all other bad things.</span>"
                You are baffled.
            </p>
            <p>
            <span className='man'>"If Satan is evil, why don't you just get rid of him? Are you not all powerful anymore?" </span>
                God is not pleased.
                <span className='god'>"Of course I'm still all powerful!  How dare you utter such vile falsisms?" </span>
                You feel several boils beginning to develop.
                <br />
                <span className='god'>"Satan did it,</span>" says God.
                You decide not to ask any more questions about what God is.
            </p>
            <p>
                <span className='man'>"Where does Satan live?" </span> you ask.
                <br />
                <span className='god'>"He rules over a place called Hell, and the souls of men whom Satan owns suffer agonizing tortures there for eternity.</span>"
                <br />
                This does not sound good to you.<span className='man'>"How do I avoid Hell?" </span>
                <br />
                God replies, <span className='god'>"I am sending my son to Earth to deliver man from evil and wash away his sins.
                All those who follow his teachings and worship him as their Lord will be saved from eternal damnation and 
                live forever in the kingdom of God.
                Unfortunately, he's not due to arrive for several hundred years, and since you won't get a chance to dedicate your 
                life to his glory, you'll be going to Hell automatically.</span>"
            </p>
        </div>
    ),

    [VISIT_SODOM]: (
        <div>
            <p>
                You are staying with relatives in Sodom and Gomorrah when several strange men show up at your door.
                They do not tell you who they are, but they have a strange, almost heavenly air about them.
                You offer them shelter.
            </p>
            <p>
                Later that night, you are awakened by a mob of angry men pound at your door.
                They want to take the strangers with them.
                When you ask them what they plan to do with the strangers, they are strangely evasive and mumble something about 
                good ol' Sodomite hospitality.
                Familiar as you are with the dynamics of a Sodomite and Gomorrahn mob,
                you realize that they will not retreat until they score.
                It occurs to you that you have two virgin daughters who might do the trick in place of the strange visitors.
            </p>
            <p>
                Do you give your daughters the opportunity to learn a lesson in the ways of the world?
            </p>
        </div>
    ),

};


// Text responses once a Yes/No question has been answered
// map of [action.type][Yes/No bool] to jsx, or
//   function(state,action) that will return a templated jsx
export const YES_NO_TEXT = {
    [ASK_FOR_SON] : {
        true: (
        <div>
            <p>
                God backs down at the last minute and does not force you to sacrifice your son.
                <br />
                Phew!
            </p>
            <p>
                The Almighty returns to his usual, arbitrary self, although the particularly harsh case of boils with which you are
                inflicted next week prompts the suspicion that he's being just a tad extra nasty.
            </p>
        </div>
    ),
        false: (
        <div>
            <p>
                Good for you! By showing that man can make moral judgement on a more advanced plane than merely following the dictates
                of a much-feared God, you have established a new relationship between man and the Almighty.
                Now that you have shown you deserve it, he can treat you with the fairness that a sentient and moral being deserves.
                </p>
            <p>
                As a reward, he showers you with celebratory boils.
            </p>
        </div>
    ),
    },

    [EXIST]: {
        true: (
            <div>
                <p>
                    Today is a good day.
                </p>
                <p>
                    The rate at which your children proliferate is exceeded only by the multiplication of your goats.
                </p>
            </div>
        ),

        false: (
            <div>
                <p>
                    Today is most definitely not a good day.
                </p>
                <p>
                    God takes away your goats and kills your children at random.
                    You wish the Almighty did not forbid you to put an end to it all.
                </p>
            </div>
        ),
    },


    [__REPLACE_POTTAGE__CHECK]: {
        true: (
            <div>
                <p>
                    You already have pottage.
                </p>
                <p>
                    Why do you need more?
                </p>
                <p>
                    How much pottage can one man need, for God's sake?
                </p>
            </div>
        ),
        false: (
            <div>
                <p>
                    Your brother is enjoying a steaming hot bowl of pottage.
                    As the your nostrils hungrily drink in the delicious aroma, you find it difficult to control yourself.
                </p>
                <p>
                    Your brother, noticing your salivation, makes you an offer.
                    You may have his pottage in exchange for your birthright.
                </p>
                <p>
                    Do you make the trade?
                </p>
            </div>
        )
    },  

    [__REPLACE_POTTAGE__CHECK_NONE]: {
        true: (
            <div>
                <p>
                    Your lust for pottage, albeit understandable, has led you to make a less-than-wise decision.
                </p>
                <p>
                    Your brother takes all of the goats which you inherited from your father and all of their goat descendants
                    (i.e. all of your worldly wealth).
                    He is also now the favored son, and therefore he will now be the one whose descendants are the chosen people of the Lord.
                </p>
                <p>
                    At least it's really high quality pottage.
                </p>
            </div>
        ),
        false: (
            <div>
                <p>
                    Good for you!
                </p>
                <p>
                    You have employed the sort of sensible judgement that most men lack when faced with pottage.
                </p>
            </div>
        ),
    },

    [VISIT_SODOM] : {
        true: (
            <div>
                <p>
                    Excellent choice! The men who were staying with you were actually angels (you had no idea, right?) and God
                    is very pleased that you kept them out of harms way.
                </p>
                <p>
                    He rewards you by whisking you and your family away from the city before he smites it and turning your wife into a
                    pillar of salt, giving you the opportunity to enjoy the company of your suddenly worldly daughters in private.
                </p>
                <p>
                    You are blessed with several more children, despite the absence of your wife.
                </p>
            </div>
        ),
        false : (
            <div>
                <p>
                    God is not happy with you!
                </p>
                <p>
                    The men who were staying with you were angels, and they were not pleased with your failure to show proper respect.
                    Granted, being as how these visitors are divinities, they handled themselves just fine when you showed them the door --
                    randy sodomites are no match for God's representatives on Earth.
                    Still, it's the principle of the thing.
                </p>
                <p>
                    God smites you, and your daughters are sold in slavery to and infidel king, where they spend the rest of their
                    short lives as middle-to-low ranking concubines.
                </p>
            </div>
        ),    
    }
};


export const OTHER_TEXT = {

    [__TOO_MANY_BOILS]: (currentAdventureState, action) => 
        <div>
            {currentAdventureState.adventureText}
            {currentAdventureState.boils} BOILS IS MORE THAN A MAN CAN TOLERATE! 
            YOU DIE, SMITTEN BY THE HAND OF GOD IN A PROLONGED, TORTUROUS AND BUBOE-INTENSE FASHION.
        </div>,

    [__TOO_MANY_REMINDERS]: (
        <div>
            <p>
                You decide that God just has to have forgotten about this covenant deal, otherwise all these terrible things
                wouldn't be happening to you.
            </p>
            <p>
                You gather all your courage and remind God once again of his promise to reward righteous men like you with good fortune.
            </p>
            <p>
                He smites you.
                </p>
        </div>
    ),

};

// HACK temporarily expose for console debugging
window.ACTION_RESPONSE_TEXT = ACTION_RESPONSE_TEXT;
