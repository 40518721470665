import React from 'react';

import { Provider } from 'react-redux';
import { createStore } from 'redux';

import ReactDOM from 'react-dom';
import './index.css';

import App from './components/App';

import reducers from './reducers';


const store = createStore(
  reducers, /* preloadedState, */
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
