import {
  ASK_GOD_QUESTION,
  ANSWER_YES_NO,
  TOGGLE_SHARE,
} from './types';

export const answeredYesNo = (answerFor, answerValue) => {
  return {
    type: ANSWER_YES_NO,
    answerValue,
    answerFor,
  };
};

export const askGodQuestion = (questionText) => {
  return {
    type: ASK_GOD_QUESTION,
    questionText,
  }
};

export const toggleShare = (showShare) => {
  return {
    type: TOGGLE_SHARE,
    showShare
  }
};

export const takeAction = (type) => {
  return { type };
};
