export const ANSWER_YES_NO = 'ANSWER_YES_NO';

export const ASK_FOR_SON = 'ASK_FOR_SON';
export const ASK_GOD_QUESTION = 'ASK_GOD_QUESTION';
export const CONSULT_SAGE = 'CONSULT_SAGE';
export const EXIST = 'EXIST';
export const LOSE_HEADS = 'LOSE_HEADS';
export const MAKE_CALF = 'MAKE_CALF';
export const REMIND_GOD = 'REMIND_GOD';
export const REPLACE_POTTAGE = 'REPLACE_POTTAGE';
export const RISE = 'RISE';
export const TOGGLE_SHARE = 'TOGGLE_SHARE';
export const STOP_BELIEVING = 'STOP_BELIEVING';
export const VISIT_NEW_TESTAMENT = 'VISIT_NEW_TESTAMENT';
export const VISIT_PARENTS = 'VISIT_PARENTS';
export const VISIT_SODOM = 'VISIT_SODOM';
