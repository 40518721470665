import { useEffect, useState } from 'react';


const GitSha = ({ url = '/git_sha.json', fieldName = 'gitSha' }) => {
    const [gitSha, setGitSha] = useState('...');

    useEffect(() => {
        async function fetchSha() {
            try {
                let response = await fetch(url)
                response = await response.json()
                setGitSha(response[fieldName])      
            } catch ( e ) {
                console.log(e);
                setGitSha('?');
            }
        };
        fetchSha()
      }, [fieldName, url]);
    

    return <span className="gitSha" data-content={gitSha}>{gitSha}</span>
}

export default GitSha;
