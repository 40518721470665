import { connect } from 'react-redux';

import { toggleShare } from '../actions';

const SITE_URL = 'http://old-testament-text-adventure.com/';

const SOCIAL_MEDIA = [
    {
        name: 'twitter',
        imageUrl: '/images/social/twitter.png',
        interpolate: o => `https://twitter.com/intent/tweet?url=${o.url}&text=${o.statsList.join('%0A')}&via=MaybeMoses`,
    },
    {
        name: 'reddit',
        imageUrl: '/images/social/reddit.png',
        interpolate: o => `https://www.reddit.com/submit?title=The%20Old%20Testament%20Text%20Adventure&text=${o.statsList.join('%0A')}%0A${o.url}`,
    },
    {
        name: 'facebook',
        imageUrl: '/images/social/facebook.png',
        interpolate: o => `https://www.facebook.com/sharer.php?u=${o.url}`,
    },
    {
        name: 'whatsapp',
        imageUrl: '/images/social/whatsapp.png',
        interpolate: o => `whatsapp://send/?text=${o.statsList.join(' ')} ${o.url}`,
    },
];

const toggleButton = (showShare, toggleShare) => {
    return (
        <button className="ui basic icon button" onClick={() => toggleShare(!showShare)}>
            <i className={`${ showShare ? 'left' : 'right'} arrow icon`} />
        </button>
    );
};

const SocialMediaShare = ({showShare, toggleShare, statsList}) => {
    if (showShare) {
        return (
            <div className="bottomLeft">
                {toggleButton(showShare, toggleShare)}
                {SOCIAL_MEDIA.map(social => {
                    const shareUrl = social.interpolate({ url: SITE_URL, statsList })
                    return (<>
                        <a href={shareUrl} target="_ott_share">
                            <img
                                className="social"
                                alt={`Share via ${social.name}`}
                                src={social.imageUrl}
                            />
                        </a>
                    </>);
                })}
            </div>
        );
    } else {
        return (
            <div className="bottomLeft">
                {toggleButton(showShare, toggleShare)}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        showShare: state.uiState.showShare,
    }
}

export default connect(
    mapStateToProps,
    { toggleShare }
)(SocialMediaShare);
