import { connect } from 'react-redux';

import {
    ASK_FOR_SON,
    CONSULT_SAGE,
    EXIST,
    LOSE_HEADS,
    MAKE_CALF,
    REMIND_GOD,
    REPLACE_POTTAGE,
    RISE,
    STOP_BELIEVING,
    VISIT_PARENTS,
    VISIT_NEW_TESTAMENT,
    VISIT_SODOM,
} from '../actions/types';

import { 
    answeredYesNo,
    askGodQuestion,
    takeAction,
} from '../actions';

import {ACTION_TEXTS} from '../data/text.js';

import GitSha from './GitSha';

// which buttons to display in which order
const ACTION_BUTTON_LIST = [
    REMIND_GOD,
    ASK_FOR_SON,
    LOSE_HEADS,
    VISIT_SODOM,
    STOP_BELIEVING,
    REPLACE_POTTAGE,
    CONSULT_SAGE,
    VISIT_PARENTS,
    MAKE_CALF,
    EXIST,
    VISIT_NEW_TESTAMENT,
];

// render the action buttons
const renderButtons = (
    { adventureState, takeAction },
    buttonList = ACTION_BUTTON_LIST,
    className = "basic black"
) => {    
    return buttonList.map( (actionButton, i) => {
        return <button
                className={`ui button actionButton ${className}`}
                key={actionButton} id={actionButton} tabIndex={i}
                onClick={() => takeAction(actionButton)}
            >
                {ACTION_TEXTS[actionButton]}
            </button>;
    });
};

// render the Yes No prompt buttons
const renderYesNo = ( { adventureState, answeredYesNo }) => {
    return <>
        <button 
            className="ui secondary button"
            key="Yes"
            onClick={() => answeredYesNo(adventureState.promptYesNoForAction, true)}
        >
            Yes
        </button>
        <button 
            className="ui button"
            key="No"
            onClick={() => answeredYesNo(adventureState.promptYesNoForAction, false)}
        >
            No
        </button>
    </>;
};

String.prototype.toFirstUpper = function() { // eslint-disable-line no-extend-native
    return this.charAt(0).toUpperCase() + this.slice(1);
}

// normalize and submit the entered question
const submitQuestion = (askGodQuestion) => {
    const element = document.getElementById("question");
    const questionValue = element.value
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/\s+/g, ' ')
        .replace(/^\s+|\s+$/g, '')
        .toFirstUpper();
    if ( questionValue ) { 
        askGodQuestion(questionValue + '?');
        element.value = '';
    }
}

const handleKeyPress = (event, callback) => {
    if(event.key === 'Enter'){
        callback();
    }
};

const renderQuestion = ({ askGodQuestion }) => {
    return (
        <div className="ui action input actionQuestion">
            <input
                    id="question"
                    type="text"
                    placeholder="Your question..."
                    onKeyPress={e => handleKeyPress(e, () => submitQuestion(askGodQuestion))} 
                />
            <button className="ui basic black button" onClick={() => submitQuestion(askGodQuestion)}>
                Ask God a Question
            </button>
        </div>
    );
    };

const Actions = (props) => {
    if ( props.adventureState.isSmote ) {
        return (
            <div className="ui container">
                {renderButtons(props, [RISE], '')}
            </div>
        );
    } else if ( props.adventureState.promptYesNoForAction ) {
        return renderYesNo(props)
    } else {
        return <>
            <div className="ui container text">
                {renderButtons(props)}
            </div>
            <div className="ui container">
                {renderQuestion(props)}
            </div>
            <div className="ui container navigation">
                <a href="/faq.html" target="_blank">FAQ</a>
                <br />
                <GitSha />
            </div>
        </>;
    }
};

const mapStateToProps = state => {
    return {
        adventureState: state.adventureState,
    }
}

export default connect(
    mapStateToProps,
    { 
        answeredYesNo,
        askGodQuestion,
        takeAction,
    }
)(Actions);
