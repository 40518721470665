import { connect } from 'react-redux';

import SocialMediaShare from './SocialMediaShare';

// pluralize the give item and count
// return the human-readable number and item name
function pluralize( itemCount, singularItemName, pluralItemName = singularItemName+'s' ) {
    if ( itemCount === 0 ) {
        return `no ${pluralItemName}`;
    } else if ( itemCount === 1 ) {
        return `one ${singularItemName}`;
    } else  {
        return `${itemCount} ${pluralItemName}`;
    }
}

// returns the adventure statistics as a list of strings
const renderStatsList = (adventureState) => {
    return [
        `You have ${ pluralize(adventureState.boils, 'boil') }.`,
        `You have ${ pluralize(adventureState.children, 'child', 'children') }.`,
        `You have ${ pluralize(adventureState.goats, 'goat') }.`,
        `You have ${ adventureState.pottage ? 'pottage' : 'no pottage' }.`,
        ( adventureState.damned ? 'You are going to Hell.' : null ),
    ].filter(s => s); // remove nulls
}

// <Stats />
const Stats = ({ adventureState }) => {
    if ( ! adventureState.isSmote && ! adventureState.promptYesNoForAction ) {
        const statsList = renderStatsList(adventureState);
        const jsxStatsList = statsList.map( 
            (text, i) => <li key={i}>{text}</li>
        );

        return (
            <div className="ui raised padded text container segment">
                <ul className="stats">
                    {jsxStatsList}
                </ul>
                <SocialMediaShare 
                    statsList={statsList} 
                />
            </div>
        );
    } else {
        return <></>;
    }
};

const mapStateToProps = state => {
    return {
        adventureState: state.adventureState,
    }
}

export default connect(mapStateToProps)(Stats);
