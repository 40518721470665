import { useRef, useEffect } from "react";

import { connect } from 'react-redux';

const AdventureText = ({ adventureState }) => {
    const myRef = useRef(null);
    useEffect(
        () => myRef.current.scrollIntoView(),
        [adventureState.adventureText]
    );

    return (
        <div className="ui raised padded text container segment" ref={myRef}>
            { adventureState.adventureText }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        adventureState: state.adventureState,
    }
}

export default connect(mapStateToProps)(AdventureText);
