import {
    ANSWER_YES_NO,
    ASK_FOR_SON,
    ASK_GOD_QUESTION,
    EXIST,
    LOSE_HEADS,
    MAKE_CALF,
    REMIND_GOD,
    REPLACE_POTTAGE,
    RISE,
    STOP_BELIEVING,
    VISIT_NEW_TESTAMENT,
    VISIT_PARENTS,
    VISIT_SODOM,
} from '../actions/types';

import {
    __REPLACE_POTTAGE__CHECK,
    __REPLACE_POTTAGE__CHECK_NONE,
    __TOO_MANY_BOILS,
    __TOO_MANY_REMINDERS,
    START_TEXT,
    OTHER_TEXT,
    YES_NO_TEXT,
} from './text';


export const DEFAULT_STATE = {
    adventureText: START_TEXT,
    promptYesNoForAction: null, // when set, is the action that prompted a Yes/No
    boils: 0,
    children: 10,
    damned: 0,
    goats: 100,
    isSmote: false,
    pottage: 1,
    remindedCount: 0,
};

// Action handlers
// map of [action.type] to handler function: (state, action) => {}
// each function returns diff object for adventureState
export const ACTION_HANDLERS = {
    // when a YesNo question is answered, defer to YES_NO_TEXT and YES_NO_ACTION
    [ANSWER_YES_NO] : (state, action) => ({
        promptYesNoForAction: null,
        ...( action.answerFor in YES_NO_TEXT 
            ? { adventureText : YES_NO_TEXT[action.answerFor][action.answerValue] }
            : {}
        ),
        ...(action.answerFor in YES_NO_ACTION
            ? YES_NO_ACTION[action.answerFor][action.answerValue](state, action)
            : {}
        ),    
    }),

    [ASK_FOR_SON] : () => ({
        promptYesNoForAction: ASK_FOR_SON,
    }),

    [ASK_GOD_QUESTION] : state => ({
        boils: state.boils + 5,
    }),

    [EXIST] : state => {
        const isGoodDay = ( Math.random() * 3 < 1 );
        const randomNumber = Math.floor(Math.random() * 10) + 1;
        return { 
            adventureText: YES_NO_TEXT[EXIST][isGoodDay],
            children: isGoodDay
                ? (state.children) + randomNumber + 1
                : state.children - randomNumber,
            goats: isGoodDay
                ? (state.goats + 1) * (randomNumber+1)
                : state.goats / randomNumber
        }
    },

    [LOSE_HEADS] : () => ({
        boils: 0, 
        children: 0,
    }),

    [MAKE_CALF] : state => ({
        children: 0,
        goats: state.goats * 10
    }),

    [REMIND_GOD] : state => ({
        remindedCount: state.remindedCount + 1,
    }),

    /// ACTION_HANDLERS[REPLACE_POTTAGE]
    ///                        |
    ///                    have pottage?
    ///   YES_NO_ACTION[__REPLACE_POTTAGE__CHECK]
    ///                        |
    ///                   v---------v
    ///                 true      false
    ///                             |
    ///        YES_NO_ACTION[__REPLACE_POTTAGE__CHECK_NONE]
    ///                             |
    ///                        v---------v
    ///                      true      false
    ///       
    // [REPLACE_POTTAGE] : (state, action) =>
    //     pseudoAnsweredYesNo(state, __REPLACE_POTTAGE__CHECK, ( state.pottage > 0 )),


    [REPLACE_POTTAGE] : state => {
        const havePottage = ( state.pottage > 0 );
        return { 
            adventureText: YES_NO_TEXT[__REPLACE_POTTAGE__CHECK][havePottage],
            promptYesNoForAction: ( havePottage ? null : __REPLACE_POTTAGE__CHECK_NONE),            
        }
    },


    [RISE] : () => DEFAULT_STATE,

    [STOP_BELIEVING] : state => ({
        boils: state.boils + 10,
        children: 0,
        goats: 0,
    }),

    [VISIT_PARENTS] : () => ({
        pottage: 0,
    }),

    [VISIT_NEW_TESTAMENT] : state => ({
        boils: state.boils + 5,
        damned: 1,
    }),

    [VISIT_SODOM]: () => ({
        promptYesNoForAction: VISIT_SODOM,
    }),
};

// Action handler once a Yes/No question has been answered
// map of [action.type][Yes/No bool] to handler function
// each function returns diff for adventureState
export const YES_NO_ACTION = {
    [__REPLACE_POTTAGE__CHECK_NONE] : {
        true: () => ({
            goats: 0,
            pottage: 1,
        }),
        false: () => ({}),
    },

    [ASK_FOR_SON] : {
        true: state => ({
            boils: state.boils + 7,
            children: state.children + 1,
        }),
        false: state => ({
            boils: state.boils + 5,
            children: state.children + 1,
        }),
    },

    [REPLACE_POTTAGE] : {
        true: () => ({
            goats: 0,
            pottage: 1,
        }),
        false: () => ({}),
    },

    [VISIT_SODOM] : {
        true: state => ({
            children: state.children + 10,
        }),
        false: () => ({
            isSmote: true,
        }),
    },

};

// array of handler functions called in order after other actions
// each function returns diff for adventureState
export const POST_ACTION_HANDLERS = [
    state => ({ // death by boils
        ...( state.boils > 20 
            ? {
                adventureText: OTHER_TEXT[__TOO_MANY_BOILS],
                isSmote: true,
            }
            : {}
        ),
    }),
    state => ({ // death by pestering god
        ...( state.remindedCount > 1
            ? {
                adventureText: OTHER_TEXT[__TOO_MANY_REMINDERS],
                isSmote: true,
            }
            : {}
        ),
    }),
    state => ({
        children: Math.floor(Math.min(100,   Math.max(0, state.children))),
        goats:    Math.floor(Math.min(10000, Math.max(0, state.goats))),
    })
];

// HACK temporarily expose for console debugging
window.ACTION_HANDLERS = ACTION_HANDLERS;
